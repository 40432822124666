import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Page,
  LoginLogo,
  LoginSubtitle,
  Input,
  LinkText,
  PromptText,
  Button,
} from 'listertech-commons-web';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      formErrors: {
        email: '',
        password: '',
      },
    };
  }

  handleUserInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
      formErrors: {
        ...this.state.formErrors,
        [name]: '',
      },
    });
  };

  validatePasswordField(value) {
    if (!value) {
      return 'Password is required';
    }
    return null;
  }

  validateEmailField(value) {
    if (!value) {
      return 'Email is required';
    }
    let emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if (!emailValid) {
      return 'Email is invalid';
    }
    return null;
  }

  errorClass(error) {
    return error.length === 0 ? '' : 'has-error';
  }

  handleSubmit = e => {
    e.preventDefault();
    this.validateForm(() => {
      this.props.login(
        this.state.email,
        this.state.password
      );
    });
  };

  handleRegister = e => {
    e.preventDefault();
    this.setState({ loginpressed: true }, this.checkFields);
    this.props.history.push('/register');
  };

  validateForm = onSuccess => {
    let passwordError = this.validatePasswordField(this.state.password);
    let emailError = this.validateEmailField(this.state.email);
    if (emailError || passwordError) {
      this.setState({
        formErrors: { email: emailError, password: passwordError },
      });
    } else {
      onSuccess();
    }
  };

  render() {
    return (
      <Page>
        <Card>
          <div style={{ width: '50%' }}>
            <form>
              <LoginLogo />
              <LoginSubtitle>Super Admin Login</LoginSubtitle>
              <Input
                type="email"
                refs="email"
                name="email"
                placeholder="Email"
                value={this.state.email}
                onChange={this.handleUserInput}
              />
              <PromptText>{this.state.formErrors.email}</PromptText>
              <Input
                type="password"
                refs="password"
                name="password"
                placeholder="Password"
                value={this.state.password}
                onChange={this.handleUserInput}
              />
              <PromptText>{this.state.formErrors.password}</PromptText>
              <PromptText>{this.props.error}</PromptText>
              <LinkText to="/forgot">Forgot Username/Password</LinkText>
              <Button
                onClick={this.handleSubmit}
                theme="dark"
                loading={this.props.loading}
              >
                Login
              </Button>
            </form>
          </div>
        </Card>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.loading['LOGIN'],
  error: state.error['LOGIN'],
});

const mapDispatchToProps = dispatch => ({
  login: (email, password) =>
    dispatch({ type: 'LOGIN', email, password }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginPage);
