/* @flow */
import React from 'react';
import styled from 'styled-components';
import { H4, InputContainer as Container, RatingBar } from 'listertech-commons-web';

type Props = {
  label: string,
  value: ?string | number,
};

const Rating = (props: Props) => {
  let { label, value } = props;
  return (
    <Container>
      <Subtitle>{label}</Subtitle>
      <RatingBar rating={value} />
    </Container>
  );
};

const Subtitle = styled(H4)`
  margin-bottom: 4px;
`;

export default Rating;
