/* @flow */
import React from 'react';
import moment from 'moment';
import { ActionButton, DataTable, ListLargeText } from 'listertech-commons-web';

import type { Visit } from 'data/visits';

type Props = {
  data: Visit[],
  onSort: (index: number) => void,
};

const AcceptedTable = (props: Props) => {
  return (
    <DataTable
      name="accepted-visits"
      headers={['Patient Name', 'Type', 'Contractor', 'City', 'Agency']}
      data={props.data}
      renderers={[
        (v: Visit) => <ListLargeText>{v.patient.name}</ListLargeText>,
        (v: Visit) => <ListLargeText>{v.type}</ListLargeText>,
        (v: Visit) => (
          <ListLargeText>{v.contractor ? v.contractor.name : ''}</ListLargeText>
        ),
        (v: Visit) => (
          <ListLargeText>{v.patient ? v.patient.city : ''}</ListLargeText>
        ),
        (v: Visit) => <ListLargeText>{v.agencyName}</ListLargeText>,
      ]}
      onSort={props.onSort}
    />
  );
};

export default AcceptedTable;
