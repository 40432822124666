/* @flow */
import type { Manager } from './index';

export const SET_MANAGERS = 'agency/SET_MANAGERS';
export const SET_DETAILS = 'agency/SET_DETAILS';
export const PUT_AGENCIES = 'agency/PUT_AGENCIES';
export const UPDATE_AGENCY_PAUSED = 'agency/UPDATE_AGENCY_PAUSED';
export const CLEAR = 'agency/CLEAR';

export type SetManagersAction = {
  type: typeof SET_MANAGERS,
  managers: Manager[],
};

export type AgencyActions = SetManagersAction;
