/* @flow */
import React from 'react';
import { ActionButton, DataTable, ListLargeText } from 'listertech-commons-web';

import type { Contractor } from 'data/contractors';

type Props = {
  data: Contractor[],
  onEnroll: (id: string) => void,
  onReject: (id: string) => void,
};

const PendingEnrollments = (props: Props) => {
  return (
    <DataTable
      name="visits"
      headers={[
        { title: 'ID #', size: { sm: 5 }},
        { title: 'Agency', size: { sm: 4 }},
        { title: 'Type', size: { sm: 3 }}
      ]}
      data={props.data.slice(0, 5)}
      renderers={[
        (c: Contractor) => <ListLargeText>{c.id}</ListLargeText>,
        (c: Contractor) => <ListLargeText>{c.agency.name}</ListLargeText>,
        (c: Contractor) => <ListLargeText>{c.type}</ListLargeText>,
      ]}
      onSort={() => {}}
    />
  );
};

export default PendingEnrollments;
