/* @flow */
import * as ActionTypes from './actionTypes';
import type { AgencyActions } from './actionTypes';
import type { AgencyState } from './index';

const initialState: AgencyState = {
  search: '',
  all: [],
  managers: [],
  details: {},
};

export default function messages(
  state: AgencyState = initialState,
  action: AgencyActions,
) {
  switch (action.type) {
    case ActionTypes.PUT_AGENCIES:
      let all = [
        ...state.all.filter(
          v => !Boolean(action.agencies.find(nv => nv._id === v._id)),
        ),
        ...action.agencies,
      ];
      return {
        ...state,
        all: all,
      };
    case ActionTypes.SET_MANAGERS:
      return {
        ...state,
        managers: action.managers,
      };
    case ActionTypes.SET_DETAILS:
      return {
        ...state,
        details: action.details,
      };
    case 'SET_AGENCIES_SEARCH':
      return {
        ...state,
        search: action.search,
      };
    case ActionTypes.UPDATE_AGENCY_PAUSED: {
      const index = state.all.findIndex(agency => agency._id === action.id);

      if (index === -1) {
        return state;
      } else {
        const copy = [...state.all];
        copy[index] = {
          ...copy[index],
          paused: action.data.paused,
        };

        return {
          ...state,
          all: copy,
        };
      }
    }
    case ActionTypes.CLEAR:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
