/* @flow */
import type { Visit } from './index';

export const PUT_VISITS = 'visits/PUT_VISITS';
export const PUT_DASHBOARD_VISITS = 'visits/PUT_DASHBOARD_VISITS';
export const ADD_VISIT = 'visits/ADD_VISIT';
export const UPDATE_VISIT = 'visits/UPDATE_VISIT';
export const VERIFY_VISIT = 'visits/VERIFY_VISIT';
export const REJECT_VISIT = 'visits/REJECT_VISIT';
export const CANCEL_VISIT = 'visits/CANCEL_VISIT';
export const REMOVE_VISIT = 'visits/REMOVE_VISIT';

export type PutVisitsAction = {
  type: typeof PUT_VISITS,
  visits: Visit[],
};

export type PutDashboardVisitsAction = {
  type: typeof PUT_DASHBOARD_VISITS,
  visits: Visit[],
};

export type AddVisitAction = {
  type: typeof ADD_VISIT,
  visit: Visit,
};

export type UpdateVisitAction = {
  type: typeof UPDATE_VISIT,
  visit: Visit,
};

export type VerifyVisitAction = {
  type: typeof VERIFY_VISIT,
  id: string,
};

export type RejectVisitAction = {
  type: typeof REJECT_VISIT,
  id: string,
};

export type CancelVisitAction = {
  type: typeof CANCEL_VISIT,
  id: string,
};

export type RemoveVisitAction = {
  type: typeof REMOVE_VISIT,
  id: string,
};

export type VisitsActions =
  | PutVisitsAction
  | AddVisitAction
  | UpdateVisitAction
  | VerifyVisitAction
  | RejectVisitAction
  | CancelVisitAction
  | RemoveVisitAction;
