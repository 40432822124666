import React from 'react';
import { connect } from 'react-redux';
import {
  Page,
  Card,
  LoginLogo,
  LoginSubtitle,
  Input,
  PromptText,
  Button,
} from 'listertech-commons-web';

class ForgotPasswordPage extends React.Component {
  state = {
    email: '',
    error: '',
    sent: false,
  };

  onSuccessfulMail = () => {
    this.setState({ sent: true });
  };

  handleEmailInput = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  handleSubmit = () => {
    this.props.sendResetLink(this.state.email, this.onSuccessfulMail);

  };

  render() {
    const { email, error, sent } = this.state;

    return (
      <Page>
        <Card>
          <div style={{ width: '50%' }}>
            <form>
              <LoginLogo />
              <LoginSubtitle>Reset Password</LoginSubtitle>
              <Input
                type="email"
                refs="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={this.handleEmailInput}
              />
              <PromptText success={sent && !error}>
                {sent && !error ? 'Email has been sent successfully' : error}
              </PromptText>
              <PromptText>{this.props.error}</PromptText>
              <Button
                onClick={this.handleSubmit}
                theme="dark"
                loading={this.props.loading}
              >
                Send Reset Link
              </Button>
            </form>
          </div>
        </Card>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  data: state,
  loading: state.loading['SEND_RESET_LINK'],
  error: state.error['SEND_RESET_LINK'],
});

const mapDispatchToProps = dispatch => ({
  sendResetLink: (email: string, onSuccess: () => void) => dispatch({ type: 'SEND_RESET_LINK', email, onSuccess }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
