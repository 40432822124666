/* @flow */
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  callGetContractors,
  callGetContractor,
  callDenyContractor,
} from '../api/agencies';
import { store } from 'data';
import ContractorActions from 'data/contractors/actions';
import { ContractorConverter } from 'api/agencies/converters';
import { PAGE_SIZE } from '../data/pagination/config';
import PaginationActions from '../data/pagination/actions';

export function* getContractors(action): Generator<Object, void, void> {
  try {
    yield put({ type: 'GET_CONTRACTORS_REQUEST' });
    const response: Object = yield call(
      callGetContractors,
      PAGE_SIZE,
      action.page,
      action.sort,
      action.desc,
      action.search,
      action.agencyId,
    );
    yield put(ContractorActions.putContractors(response.data));
    yield put(
      PaginationActions.setPage(
        'contractors',
        action.page,
        response.headers.count,
        response.data.map(v => v._id),
      ),
    );
    yield put({ type: 'GET_CONTRACTORS_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'GET_CONTRACTORS_FAILURE',
      error: error.message,
    });
  }
}

export function* getContractor(action: {
  id: string,
}): Generator<Object, void, void> {
  try {
    yield put({ type: 'GET_CONTRACTOR_REQUEST' });
    const response: Object = yield call(callGetContractor, action.id);
    yield put(ContractorActions.putContractors(response ? [response] : []));
    yield put({ type: 'GET_CONTRACTOR_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'GET_CONTRACTOR_FAILURE',
      error: error.message,
    });
  }
}

export function* denyContractorForAgency(action: {
  contractorId: string,
  agencyId: string,
  onSuccess: Function,
}): Generator<Object, void, void> {
  try {
    yield put({ type: 'DENY_CONTRACTOR_FOR_AGENCY_REQUEST' });
    yield call(callDenyContractor, action.contractorId, action.agencyId);
    yield put({ type: 'DENY_CONTRACTOR_FOR_AGENCY_SUCCESS' });
    if (action.onSuccess) {
      action.onSuccess();
    }
  } catch (e) {
    yield put({
      type: 'DENY_CONTRACTOR_FOR_AGENCY_FAILURE',
      error: e.message,
    });
  }
}

const contractorsSaga = [
  takeLatest('GET_CONTRACTORS', getContractors),
  takeLatest('GET_CONTRACTOR', getContractor),
  takeLatest('DENY_CONTRACTOR_FOR_AGENCY', denyContractorForAgency),
];

export default contractorsSaga;
