import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import LoginPage from 'pages/LoginPage';
import DashboardPage from 'pages/DashboardPage';
import VisitsPage from 'pages/VisitsPage';
import VisitDetailsPage from 'pages/VisitDetailsPage';
import ContractorsPage from 'pages/ContractorsPage';
import ContractorDetailsPage from 'pages/ContractorDetailsPage';
import AccountPage from 'pages/AccountPage';
import AllDocumentsPage from 'pages/AllDocumentsPage';
import GlobalStyle from './GlobalStyle';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import ResetPasswordPage from 'pages/ResetPasswordPage'
import AgenciesPage from 'pages/AgenciesPage';
import { Helmet } from 'react-helmet';

import {call} from "../api/core";
import AgencyDetailsPage from "../pages/AgencyDetailsPage";

const AuthRoute = ({ component: Component, title, isAuthenticated, ...rest }) => (
  <>
    <Helmet>
      <title> {title} </title>
    </Helmet>
    <Route
      {...rest}
      render={props =>
        isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login' }} />
        )
      }
    />
  </>
);

const UnauthRoute = ({ component: Component, title, isAuthenticated, ...rest }) => (
  <>
    <Helmet>
      <title> {title} </title>
    </Helmet>
    <Route
      {...rest}
      render={props =>
        isAuthenticated === false ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/' }} />
        )
      }
    />
  </>
);

const Routes = props => {
  return (
  <div>
  <GlobalStyle />
  <Switch>
      <UnauthRoute
        path="/login"
        title = "Visitry | Login"
        exact
        component={LoginPage}
        isAuthenticated={props.isAuthenticated}
      />
      <UnauthRoute
        path="/forgot"
        title = "Visitry | Password Reset"
        exact
        component={ForgotPasswordPage}
        isAuthenticated={props.isAuthenticated}
      />
      <UnauthRoute
        path="/reset/:token"
        title = "Visitry | Password Reset"
        exact
        component={ResetPasswordPage}
        isAuthenticated={props.isAuthenticated}
      />
      <AuthRoute
        path="/"
        title = "Visitry | Dashboard"
        exact
        component={DashboardPage}
        isAuthenticated={props.isAuthenticated}
      />
      <AuthRoute
        path="/visits"
        title = "Visitry | Visits"
        exact
        component={VisitsPage}
        isAuthenticated={props.isAuthenticated}
      />
      <AuthRoute
        path="/visits/:visitId"
        title = "Visitry | Visits"
        component={VisitDetailsPage}
        isAuthenticated={props.isAuthenticated}
      />
      <AuthRoute
        path="/contractors"
        title = "Visitry | Contractors"
        exact
        component={ContractorsPage}
        isAuthenticated={props.isAuthenticated}
      />
      <AuthRoute
        path="/contractors/:contractorId"
        title = "Visitry | Contractors"
        component={ContractorDetailsPage}
        isAuthenticated={props.isAuthenticated}
      />
      <AuthRoute
        path="/account"
        title = "Visitry | Account"
        exact
        component={AccountPage}
        isAuthenticated={props.isAuthenticated}
      />
      <AuthRoute
        path="/documents/:contractorId"
        title = "Visitry | Documents"
        exact
        component={AllDocumentsPage}
        isAuthenticated={props.isAuthenticated}
      />
      <AuthRoute
        path="/agencies/:agencyId"
        title = "Visitry | Agency"
        exact
        component={AgencyDetailsPage}
        isAuthenticated={props.isAuthenticated}
      />
      <AuthRoute
        path="/agencies"
        title = "Visitry | Agency"
        exact
        component={AgenciesPage}
        isAuthenticated={props.isAuthenticated}
      />
    </Switch>
  </div>
);
}

const mapStateToProps = state => ({
  isAuthenticated: Boolean(state.session.accessToken) && state.session.expire >= Date.now(),
});

export default connect(mapStateToProps)(Routes);
