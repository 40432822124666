/* @flow */
import * as ActionTypes from './actionTypes';
import type {
  SetPageAction,
  SetActivePageAction,
  ClearPagesAction,
  TakeFromAction,
} from './actionTypes';

const setPage = (
  key: string,
  page: number,
  count: number,
  ids: string[],
): SetPageAction => ({
  type: ActionTypes.SET_PAGE,
  key,
  page,
  count,
  ids,
});

const setActivePage = (key: string, page: number): SetActivePageAction => ({
  type: ActionTypes.SET_ACTIVE_PAGE,
  key,
  page,
});

const clearPages = (key: string): ClearPagesAction => ({
  type: ActionTypes.CLEAR_PAGES,
  key,
});

const takeFrom = (id: string, key: string): TakeFromAction => ({
  type: ActionTypes.TAKE_FROM,
  id,
  key,
});

export default {
  setPage,
  setActivePage,
  clearPages,
  takeFrom,
};
