/* @flow */

import React from 'react';
import { Row, Col } from 'react-grid-system';
import type { RouterHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  PageHeader,
  OptionButtonGroup,
  MenuOptionButton,
  PageDetailHeader,
  Field,
  ExtraSmallText,
  WeekButtonGroup,
  LoadingIndicator,
  Divider,
} from 'listertech-commons-web';

import SideNavPage from 'components/SideNavPage';

type Props = {
  history: RouterHistory,
  match: any,
  agency: ?any,
};


const updateButtons = [
  {
    Title: 'M',
    value: 'M',
  },
  {
    Title: 'T',
    value: 'T',
  },
  {
    Title: 'W',
    value: 'W',
  },
  {
    Title: 'Th',
    value: 'Th',
  },
  {
    Title: 'F',
    value: 'F',
  },
  {
    Title: 'S',
    value: 'S',
  },
  {
    Title: 'Su',
    value: 'Su',
  },
];

class AgencyDetailsPage extends React.Component<Props> {
  componentDidMount () {
    if (!this.props.agency) {
      this.props.getAgency(this.props.match.params.agencyId);
    }
  }

  handleBack = () => {
    this.props.history.goBack();
  };

  formatDate = (date, format = 'MM/DD/YYYY') => {
    return date ? moment(date).format(format) : '';
  };

  showContractors = () => {
    if (this.props.agency) {
      this.props.history.push(`/contractors?agency_id=${this.props.agency._id}`);
    }
  }

  onTogglePaused = () => {
    const newStatus = !this.props.agency.paused;

    if (window.confirm(`Are you sure you want to ${newStatus ? 'pause' : 'resume'} this agency?`)) {
      this.props.updatePaused(this.props.agency._id, newStatus);
    }
  }

  render() {
    // const { visit = { patient: {}, manager: {}, doctor: {} } } = this.props;
    // const patientAddress = this.formatAddress(visit.patient);

    if (this.props.loading) return <SideNavPage><LoadingIndicator /></SideNavPage>

    return (
      <SideNavPage>
        <Row justify="between">
          <PageHeader subtitle="Agency">
            {this.props.agency && this.props.agency.name}
          </PageHeader>
          <OptionButtonGroup>
            <MenuOptionButton onClick={this.handleBack}>Back</MenuOptionButton>
            <MenuOptionButton onClick={this.showContractors}>Contractors</MenuOptionButton>
            <MenuOptionButton onClick={this.onTogglePaused}>{this.props.agency && this.props.agency.paused ? 'Resume' : 'Pause'}</MenuOptionButton>
          </OptionButtonGroup>
        </Row>
        <PageDetailHeader>Visits</PageDetailHeader>
        <Divider/>
        <Row>
          <Col sm={12} md={4} lg={3} xl={1}>
            <Field label="Open" value={this.props.agency && this.props.agency.openVisitsCount} />
          </Col>
          <Col sm={12} md={4} lg={3} xl={1}>
            <Field label="Accepted" value={this.props.agency && this.props.agency.acceptedVisitsCount} />
          </Col>
          <Col sm={12} md={4} lg={3} xl={1}>
            <Field label="Pending" value={this.props.agency && this.props.agency.pendingVisitsCount} />
          </Col>
          <Col sm={12} md={4} lg={3} xl={1}>
            <Field label="Canceled" value={this.props.agency && this.props.agency.canceledVisitsCount} />
          </Col>
        </Row>
        <PageDetailHeader>General Info</PageDetailHeader>
        <Divider/>
        <Row>
          <Col sm={12} md={4} lg={3} xl={2}>
            <Field label="Agency Name" value={this.props.agency && this.props.agency.name} />
          </Col>
          <Col sm={12} md={4} lg={3} xl={2}>
            <Field label="Agency Phone" value={this.props.agency && this.props.agency.phone} />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={4} lg={3} xl={2}>
            <Field label="Street Address" value={this.props.agency && this.props.agency.street_address} />
          </Col>
          <Col sm={12} md={4} lg={3} xl={1}>
            <Field label="Suite" value={this.props.agency && this.props.agency.suite_number} />
          </Col>
          <Col sm={12} md={4} lg={3} xl={1}>
            <Field label="City" value={this.props.agency && this.props.agency.city} />
          </Col>
          <Col sm={12} md={4} lg={3} xl={1}>
            <Field label="State" value={this.props.agency && this.props.agency.state} />
          </Col>
          <Col sm={12} md={4} lg={3} xl={1}>
            <Field label="Zip" value={this.props.agency && this.props.agency.zip} />
          </Col>
        </Row>
        <PageDetailHeader>Primary Contact</PageDetailHeader>
        <Divider/>
        <Row>
          <Col sm={12} md={4} lg={3} xl={2}>
            <Field label="Name" value={this.props.agency && this.props.agency.director_name} />
          </Col>
          <Col sm={12} md={4} lg={3} xl={2}>
            <Field label="Phone" value={this.props.agency && this.props.agency.director_phone} />
          </Col>
          <Col sm={12} md={4} lg={3} xl={2}>
            <Field label="Ext" value={this.props.agency && this.props.agency.director_ext} />
          </Col>
          <Col sm={12} md={4} lg={3} xl={2}>
            <Field label="Email" value={this.props.agency && this.props.agency.director_email} />
          </Col>
        </Row>
        <PageDetailHeader>Licences & Certifications</PageDetailHeader>
        <Divider/>
        <Row>
          <Col sm={12} md={4} lg={3} xl={2}>
            <Field label="CMS Certification #" value={this.props.agency && this.props.agency.cms_certification_number} />
          </Col>
          <Col sm={12} md={4} lg={3} xl={2}>
            <Field label="Expiration Date" value={this.props.agency && this.formatDate(this.props.agency.cms_certification_expired_at)} />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={4} lg={3} xl={2}>
            <Field label="State Business Licence/Sunbiz #" value={this.props.agency && this.props.agency.business_license_number} />
          </Col>
          <Col sm={12} md={4} lg={3} xl={2}>
            <Field label="Expiration Date" value={this.props.agency && this.formatDate(this.props.agency.business_license_expired_at)} />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={4} lg={3} xl={2}>
            <Field label="AHCA #" value={this.props.agency && this.props.agency.ahca_number} />
          </Col>
          <Col sm={12} md={4} lg={3} xl={2}>
            <Field label="Expiration Date" value={this.props.agency && this.formatDate(this.props.agency.ahca_expired_at)} />
          </Col>
        </Row>
        <PageDetailHeader>Medicare Week</PageDetailHeader>
        <Divider/>
        <Row>
          <Col>
            <ExtraSmallText marginBottom="10px">Starts</ExtraSmallText>
            <WeekButtonGroup
              isHorizontal
              buttons={updateButtons}
              value={this.props.agency && this.props.agency.medical_week_start}
            />
          </Col>
          <Col>
            <ExtraSmallText marginBottom="10px">Ends</ExtraSmallText>
            <WeekButtonGroup
              isHorizontal
              buttons={updateButtons}
              value={this.props.agency && this.props.agency.medial_week_end}
            />
          </Col>
        </Row>
      </SideNavPage>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  agency: state.agency.all.find(
    a => a._id === ownProps.match.params.agencyId,
  ),
  loading: state.loading['GET_AGENCY'],
});

const mapDispatchToProps = dispatch => ({
  updatePaused: (agencyId, status) => dispatch({ type: 'UPDATE_PAUSED', agencyId, status }),
  getAgency: (id: string) => dispatch({ type: 'GET_AGENCY', id }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgencyDetailsPage);
