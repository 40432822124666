/* @flow */
import * as ActionTypes from './actionTypes';
import type { Contractor } from './index';

const putContractors = (contractors: Contractor[]) => ({
  type: ActionTypes.PUT_CONTRACTORS,
  contractors,
});

export default {
  putContractors,
};
