/* @flow */
import React from 'react';
import moment from 'moment';
import { ActionButton, DataTable, ListLargeText } from 'listertech-commons-web';

import type { Visit } from 'data/visits';

type Props = {
  data: Visit[],
  onSort: (index: number) => void,
  onRemove: (id: string) => void,
};

const CanceledTable = (props: Props) => {
  return (
    <DataTable
      name="canceled-visits"
      headers={[
        'Patient Name',
        'Date',
        'Type',
        'Contractor',
        'Agency',
        'Action',
      ]}
      data={props.data}
      renderers={[
        (v: Visit) => <ListLargeText>{v.patient.name}</ListLargeText>,
        (v: Visit) => (
          <ListLargeText>
            {v.submission && v.submission.canceledDate
              ? moment(v.submission.canceledDate).format('MM/DD')
              : ''}
          </ListLargeText>
        ),
        (v: Visit) => <ListLargeText>{v.type}</ListLargeText>,
        (v: Visit) => (
          <ListLargeText>{v.contractor ? v.contractor.name : ''}</ListLargeText>
        ),
        (v: Visit) => <ListLargeText>{v.agencyName}</ListLargeText>,
        (v: Visit) => (
          <React.Fragment>
            <ActionButton
              red
              onClick={e => {
                e.preventDefault();
                props.onRemove(v.id);
              }}
              key={`canceled-visits-remove-${v.id}`}
            >
              Remove
            </ActionButton>
          </React.Fragment>
        ),
      ]}
      onSort={props.onSort}
    />
  );
};

export default CanceledTable;
