import * as Types from './actionTypes';

const initialState = {
  acceptedVisitsLength: 0,
  agencies: [],
  agenciesLength: 0,
  availableVisitsLength: 0,
  canceledVisitsLength: 0,
  contractorsLength: 0,
  pendingVisitsLength: 0,
  visits: [],
  visitsLength: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.SAVE_DASHBOARD_DATA:
      return {
        ...state,
        ...action.data,
      }
    case Types.UPDATE_AGENCY_PAUSED: {
      const index = state.agencies.findIndex(agency => agency._id === action.id);

      if (index === -1) {
        return state;
      } else {
        const copy = [...state.agencies];
        copy[index] = {
          ...copy[index],
          paused: action.data.paused,
        }

        return {
          ...state,
          agencies: copy,
        }
      }
    }
    default:
      return state;
  }
}
