/* @flow */
import React, { Component, Fragment } from 'react';
import { Row, Col } from 'react-grid-system';
import { connect } from 'react-redux';
import querySearch from "stringquery";
import type { RouterHistory } from 'react-router-dom';
import {
  PageHeader,
  MenuOptionButton,
  PageSelector,
  OptionButtonGroup,
  LoadingIndicator,
  PromptText,
  MenuOptionsSearch,
  Images,
} from 'listertech-commons-web';

import SideNavPage from 'components/SideNavPage';
import AcceptedTable from './AcceptedTable';
import type { Visit } from 'data/visits';
import type { Paginator } from 'data/pagination';
import { PAGE_SIZE } from 'data/pagination/config';
import PaginationActions from 'data/pagination/actions';
import { capitalize } from 'util/StringUtils';

import 'components/PaginationStyle/PaginationStyle.css';

import AgenciesTable from "./AcceptedTable";

type Props = {
  history: RouterHistory,
  getVisits: (
    status: string,
    page: number,
    sort: string,
    desc: boolean,
  ) => void,
  setActivePage: (key: string, page: number) => void,
  setSearch: (search: string) => void,
  search: string,
  loading: boolean,
  error: string,
  all: Visit[],
  open: Paginator,
  accepted: Paginator,
  pending: Paginator,
  canceled: Paginator,
  paid: Paginator,
  verifyVisit: (id: string) => void,
};

type State = {
};

class VisitsPage extends Component<Props, State> {
  state = {};

  componentDidMount() {
    this.fetchData(0, true);
  }

  handlePageChange = (page) => {
    this.fetchData(page - 1);
  };

  fetchData = (page: number, forceFetch: boolean) => {
    if (!Boolean(this.props.pagination.pages[page]) || forceFetch) {
      this.props.getAgencies(
        page,
        'created_at',
        true,
        this.props.search,
      );
    } else {
      this.props.setActivePage('agencies', page);
    }
  };

  handleSearchChange = (search: string) => {
    this.props.setSearch(search);
    this.fetchData(0, true)
  };

  handleDetailsPress = (id: string) => {
    this.props.history.push(`/agencies/${id}`);
  }

  onTogglePaused = (agencyId, newStatus) => {
    if (window.confirm(`Are you sure you want to ${newStatus ? 'pause' : 'resume'} this agency?`)) {
      this.props.updatePaused(agencyId, newStatus);
    }
  }

  render() {
    const paginator = this.props.pagination;
    const ids = paginator.pages[paginator.currentPage || 0] || [];

    const agencies = this.props.all.filter(a => ids.includes(a._id));

    return (
      <SideNavPage>
        <Row justify="space-between">
          <PageHeader>Agencies</PageHeader>
          <OptionButtonGroup>
            <MenuOptionsSearch initialValue={this.props.search} onChange={this.handleSearchChange} />
            <MenuOptionButton>
              <img
                style={{ cursor: 'pointer' }}
                src={Images.filter}
                alt="filter"
              ></img>
            </MenuOptionButton>
          </OptionButtonGroup>
        </Row>
        {this.props.loading ? (
          <LoadingIndicator />
        ) : this.props.error ? (
          <PromptText>{this.props.error}</PromptText>
        ) : (
          <AgenciesTable onTogglePaused={this.onTogglePaused} data={agencies} onDetails={this.handleDetailsPress} onSort={() => {}} />
        )}
        <Row justify="flex-end" style={{ marginRight: 20 }}>
          {paginator.objectCount && paginator.pagesCount ? (
            <PageSelector
              total={paginator.objectCount}
              limit={PAGE_SIZE}
              pageCount={paginator.pagesCount}
              currentPage={paginator.currentPage + 1 || 1}
              onPageChange={this.handlePageChange}
            />
          ) : null}
        </Row>
      </SideNavPage>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.loading['GET_AGENCIES'],
    error: state.error['GET_AGENCIES'],
    all: state.agency.all,
    search: state.agency.search,
    pagination: state.pagination.agencies,
  };
};

const mapDispatchToProps = dispatch => ({
  getAgencies: (page, sort, desc, search) =>
    dispatch({ type: 'GET_AGENCIES', page, sort, desc, search }),
  setSearch: search =>
    dispatch({ type: 'SET_AGENCIES_SEARCH', search }),
  setActivePage: (key, page) =>
    dispatch(PaginationActions.setActivePage(key, page)),
  updatePaused: (agencyId, status) => dispatch({ type: 'UPDATE_PAUSED', agencyId, status }),
});

export default connect(mapStateToProps, mapDispatchToProps)(VisitsPage);
