import React, { Component } from 'react';
import { Row, Col } from 'react-grid-system';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Card,
  PromptText,
  ExtraSmallText,
  WeekButtonGroup,
  Button,
  ScrollWrapper,
  DarkText,
  Divider,
  PageHeader,
  MenuOptionButton,
  PageDetailHeader,
  OptionButtonGroup,
  H4,
  Input,
  Images,
} from 'listertech-commons-web';
import SideNavPage from 'components/SideNavPage';
import type { RegisterAgencyDto } from 'api/agencies/types';
import validate from 'util/ValidationWrapper';

import AccountForm from './AccountForm';

const formValidationRules = {
  contact_email: {
    presence: {
      message: '^Email is required',
      allowEmpty: false,
    },
  },
  retypepassword: {
    equality: {
      attribute: 'newpassword',
      message: '^Passwords do not match',
    },
  },
  contact_name: {
    presence: {
      message: '^Name is required',
      allowEmpty: false,
    },
  },
  contact_phone: {
    presence: {
      message: '^Phone is required',
      allowEmpty: false,
    },
  },
  contact_ext: {
    presence: {
      message: '^Extension is required',
      allowEmpty: true,
    },
  },
  name: {
    presence: {
      message: '^Name is required',
      allowEmpty: false,
    },
  },
  phone: {
    presence: {
      message: '^Phone is required',
      allowEmpty: false,
    },
  },
  street_address: {
    presence: {
      message: '^Address is required',
      allowEmpty: false,
    },
  },
  suite_number: {
    presence: {
      message: '^Suite number is required',
      allowEmpty: true,
    },
  },
  city: {
    presence: {
      message: '^City is required',
      allowEmpty: false,
    },
  },
  state: {
    presence: {
      message: '^State is required',
      allowEmpty: false,
    },
  },
  zip: {
    presence: {
      message: '^Zipcode is required',
      allowEmpty: false,
    },
  },
  director_name: {
    presence: {
      message: '^Director name is required',
      allowEmpty: false,
    },
  },
  director_email: {
    presence: {
      message: '^Director email is required',
      allowEmpty: false,
    },
  },
  director_phone: {
    presence: {
      message: '^Phone is required',
      allowEmpty: false,
    },
  },
  director_ext: {
    presence: {
      message: '^Extension is required',
      allowEmpty: true,
    },
  },
  cms_certification_number: {
    presence: {
      message: '^Certification is required',
      allowEmpty: false,
    },
  },
  cms_certification_expired_at: {
    presence: {
      message: '^Expiration is required',
      allowEmpty: false,
    },
  },
  business_license_number: {
    presence: {
      message: '^License number is required',
      allowEmpty: false,
    },
  },
  business_license_expired_at: {
    presence: {
      message: '^Expiration is required',
      allowEmpty: false,
    },
  },
  ahca_number: {
    presence: {
      message: '^Number is required',
      allowEmpty: false,
    },
  },
  ahca_expired_at: {
    presence: {
      message: '^Expiration is required',
      allowEmpty: false,
    },
  },
  default_visit_rate: {
    presence: {
      message: '^Rate is required',
      allowEmpty: false,
    },
  },
  default_dc_rate: {
    presence: {
      message: '^Rate is required',
      allowEmpty: false,
    },
  },
  default_eval_rate: {
    presence: {
      message: '^Rate is required',
      allowEmpty: false,
    },
  },
  default_oasis_soc_rate: {
    presence: {
      message: '^Rate is required',
      allowEmpty: false,
    },
  },
  default_oasis_doc_rate: {
    presence: {
      message: '^Rate is required',
      allowEmpty: false,
    },
  },
  default_oasis_dc_rate: {
    presence: {
      message: '^Rate is required',
      allowEmpty: false,
    },
  },
  default_oasis_rechart_rate: {
    presence: {
      message: '^Rate is required',
      allowEmpty: false,
    },
  },
  default_visit_pta_rate: {
    presence: {
      message: '^Rate is required',
      allowEmpty: false,
    },
  },
  default_reeval_rate: {
    presence: {
      message: '^Rate is required',
      allowEmpty: false,
    },
  },
  medical_week_start: {
    presence: {
      message: '^Week Start is required',
      allowEmpty: false,
    },
  },
  medial_week_end: {
    presence: {
      message: '^Week End is required',
      allowEmpty: false,
    },
  },
};

class AccountPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      contact_email: '',
      password: '',
      newpassword: '',
      retypepassword: '',
      contact_name: '',
      contact_phone: '',
      contact_ext: '',
      name: '',
      phone: '',
      street_address: '',
      suite_number: null,
      city: '',
      state: '',
      zip: null,
      bank_routing_number: null,
      bank_account_number: null,
      bank_ein_number: null,
      director_name: '',
      director_email: '',
      director_phone: '',
      director_ext: '',
      cms_certification_number: null,
      cms_certification_expired_at: '',
      business_license_number: null,
      business_license_expired_at: '',
      ahca_number: null,
      ahca_expired_at: '',
      default_visit_rate: null,
      default_dc_rate: null,
      default_eval_rate: null,
      default_oasis_soc_rate: null,
      default_oasis_doc_rate: null,
      default_oasis_dc_rate: null,
      default_oasis_rechart_rate: null,
      default_visit_pta_rate: null,
      default_reeval_rate: null,
      medical_week_start: '',
      medial_week_end: '',
      formErrors: {
        contact_email: '',
        password: '',
        newpassword: '',
        retypepassword: '',
        contact_name: '',
        contact_phone: '',
        contact_ext: '',
        name: '',
        phone: '',
        street_address: '',
        suite_number: null,
        city: '',
        state: '',
        zip: null,
        bank_routing_number: null,
        bank_account_number: null,
        bank_ein_number: null,
        director_name: '',
        director_email: '',
        director_phone: '',
        director_ext: '',
        cms_certification_number: null,
        cms_certification_expired_at: '',
        business_license_number: null,
        business_license_expired_at: '',
        ahca_number: null,
        ahca_expired_at: '',
        default_visit_rate: null,
        default_dc_rate: null,
        default_eval_rate: null,
        default_oasis_soc_rate: null,
        default_oasis_doc_rate: null,
        default_oasis_dc_rate: null,
        default_oasis_rechart_rate: null,
        default_visit_pta_rate: null,
        default_reeval_rate: null,
        medical_week_start: '',
        medial_week_end: '',
      },
      formValid: false,
      updateButtons: [
        {
          Title: 'M',
          value: 'M',
        },
        {
          Title: 'T',
          value: 'T',
        },
        {
          Title: 'W',
          value: 'W',
        },
        {
          Title: 'Th',
          value: 'Th',
        },
        {
          Title: 'F',
          value: 'F',
        },
        {
          Title: 'S',
          value: 'S',
        },
        {
          Title: 'Su',
          value: 'Su',
        },
      ],
    };
  }

  componentDidMount() {
    if (this.props.details.name) {
      this.loadData();
    } else {
      this.props.getAgencyDetails();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.details !== prevProps.details) {
      this.loadData();
    }
  }

  loadData = () => {
    const { details } = this.props;
    this.setState({
      ...details,
      cms_certification_expired_at: details.cms_certification_expired_at ? moment(details.cms_certification_expired_at).format('MM/DD/YYYY') : '',
      business_license_expired_at: details.business_license_expired_at ? moment(details.business_license_expired_at).format('MM/DD/YYYY') : '',
      ahca_expired_at: details.ahca_expired_at ? moment(details.ahca_expired_at).format('MM/DD/YYYY') : '',
    });
  };

  handleUserInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
      formErrors: {
        ...this.state.formErrors,
        [name]: '',
      },
    });
  };

  validateForm = (onSuccess): Object => {
    let {
      contact_email,
      password,
      newpassword,
      retypepassword,
      contact_name,
      contact_phone,
      contact_ext,
      name,
      phone,
      street_address,
      suite_number,
      city,
      state,
      zip,
      bank_routing_number,
      bank_account_number,
      bank_ein_number,
      director_name,
      director_email,
      director_phone,
      director_ext,
      cms_certification_number,
      cms_certification_expired_at,
      business_license_number,
      business_license_expired_at,
      ahca_number,
      ahca_expired_at,
      default_visit_rate,
      default_dc_rate,
      default_eval_rate,
      default_oasis_soc_rate,
      default_oasis_doc_rate,
      default_oasis_dc_rate,
      default_oasis_rechart_rate,
      default_visit_pta_rate,
      default_reeval_rate,
      medical_week_start,
      medial_week_end,
    } = this.state;

    let data: RegisterAgencyDto = {
      contact_email,
      password,
      newpassword,
      retypepassword,
      contact_name,
      contact_phone,
      contact_ext,
      name,
      phone,
      street_address,
      suite_number,
      city,
      state,
      zip,
      bank_routing_number,
      bank_account_number,
      bank_ein_number,
      director_name,
      director_email,
      director_phone,
      director_ext,
      cms_certification_number,
      cms_certification_expired_at,
      business_license_number,
      business_license_expired_at,
      ahca_number,
      ahca_expired_at,
      default_visit_rate,
      default_dc_rate,
      default_eval_rate,
      default_oasis_soc_rate,
      default_oasis_doc_rate,
      default_oasis_dc_rate,
      default_oasis_rechart_rate,
      default_visit_pta_rate,
      default_reeval_rate,
      medical_week_start,
      medial_week_end,
    };

    let valerror = validate(data, formValidationRules);

    if (valerror) {
      alert('Please fill out all the fields.');

      this.setState({
        formErrors: {
          ...this.state.formErrors,
          ...valerror,
        },
      });
    } else {
      onSuccess(data);
    }
  };

  handleStartChange = value => {
    this.setState({
      medical_week_start: value,
    });
  };

  handleEndChange = value => {
    this.setState({
      medial_week_end: value,
    });
  };

  errorClass(error) {
    return error.length === 0 ? '' : 'has-error';
  }

  handleSubmit = () => {
    const { details } = this.props;
    this.validateForm(data => {
      this.props.updateAgencyDetails({
        ...data,
        id: details._id || details.id,
        contact_id: details.contact_id,
      }, this.onUpdateSuccess, this.onUpdateError);
    });
  };

  onUpdateSuccess = () => {
    alert('Agency updated successfully');
  };

  onUpdateError = message => {
    alert(message);
  };

  handleLogout = () => {
    this.props.logout();
  };

  render() {
    const { updateButtons } = this.state;

    return (
      <SideNavPage padding="0px">
        <Row>
          <Card style={{ paddingTop: 0 }}>
            <AccountForm onSubmit={e => e.preventDefault()}>
              <Row justify="space-between" align="center">
                <Col>
                  <PageHeader>Account</PageHeader>
                </Col>
                <Col>
                  <OptionButtonGroup>
                    <MenuOptionButton green icon={Images.saveGreen} onClick={this.handleSubmit}>
                      Save Changes
                    </MenuOptionButton>
                    <MenuOptionButton red icon={Images.logoutRed} onClick={this.handleLogout}>
                      Logout
                    </MenuOptionButton>
                  </OptionButtonGroup>
                </Col>
              </Row>
              <Divider />
              <ScrollWrapper>
                <PageDetailHeader>General Info</PageDetailHeader>
                <Row>
                  <Col sm={3}>
                    <H4>Agency Name</H4>
                    <Input
                      type="name"
                      refs="name"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.name)}
                      error={this.state.formErrors.name}
                    />
                  </Col>
                  <Col sm={3}>
                    <H4>Agency Phone</H4>
                    <Input
                      type="phone"
                      refs="phone"
                      name="phone"
                      value={this.state.phone}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.phone)}
                      error={this.state.formErrors.phone}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <H4>Street Address</H4>
                    <Input
                      type="street_address"
                      refs="street_address"
                      name="street_address"
                      value={this.state.street_address}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.street_address)}
                      error={this.state.formErrors.street_address}
                    />
                  </Col>
                  <Col sm={1.5}>
                    <H4>Apt #</H4>
                    <Input
                      type="suite_number"
                      refs="suite_number"
                      name="suite_number"
                      value={this.state.suite_number}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.suite_number)}
                      error={this.state.formErrors.suite_number}
                    />
                  </Col>
                  <Col sm={3}>
                    <H4>City</H4>
                    <Input
                      type="city"
                      refs="city"
                      name="city"
                      value={this.state.city}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.city)}
                      error={this.state.formErrors.city}
                    />
                  </Col>
                  <Col sm={1.5}>
                    <H4>State</H4>
                    <Input
                      type="state"
                      refs="state"
                      name="state"
                      value={this.state.state}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.state)}
                      error={this.state.formErrors.state}
                    />
                  </Col>
                  <Col sm={2}>
                    <H4>Zip</H4>
                    <Input
                      type="zip"
                      refs="zip"
                      name="zip"
                      value={this.state.zip}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.zip)}
                      error={this.state.formErrors.zip}
                    />
                  </Col>
                </Row>
                <PageDetailHeader>Change Password</PageDetailHeader>
                <Row>
                  <Col sm={3}>
                    <H4>Current Password</H4>
                    <Input
                      type="password"
                      refs="password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.password)}
                      error={this.state.formErrors.password}
                    />
                  </Col>
                  <Col sm={3}>
                    <H4>New Password</H4>
                    <Input
                      type="password"
                      refs="newpassword"
                      name="newpassword"
                      value={this.state.newpassword}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.retypepassword)}
                      error={this.state.formErrors.retypepassword}
                    />
                  </Col>
                  <Col sm={3}>
                    <H4>Re-type Password</H4>
                    <Input
                      type="password"
                      refs="retypepassword"
                      name="retypepassword"
                      value={this.state.retypepassword}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.retypepassword)}
                      error={this.state.formErrors.retypepassword}
                    />
                  </Col>
                </Row>
                <PageDetailHeader>Primary Contact</PageDetailHeader>
                <Row>
                  <Col sm={3}>
                    <H4>Name</H4>
                    <Input
                      placeholder="First & Last Name"
                      type="contact_name"
                      refs="contact_name"
                      name="contact_name"
                      value={this.state.contact_name}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.contact_name)}
                      error={this.state.formErrors.contact_name}
                    />
                  </Col>
                  <Col sm={3}>
                    <H4>Email</H4>
                    <Input
                      type="contact_email"
                      refs="contact_email"
                      name="contact_email"
                      value={this.state.contact_email}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.contact_email)}
                      error={this.state.formErrors.contact_email}
                    />
                  </Col>
                  <Col sm={3}>
                    <H4>Phone</H4>
                    <Input
                      type="contact_phone"
                      refs="contact_phone"
                      name="contact_phone"
                      value={this.state.contact_phone}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.contact_phone)}
                      error={this.state.formErrors.contact_phone}
                    />
                  </Col>
                  <Col sm={1}>
                    <H4>Ext</H4>
                    <Input
                      type="contact_ext"
                      refs="contact_ext"
                      name="contact_ext"
                      value={this.state.contact_ext}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.contact_ext)}
                      error={this.state.formErrors.contact_ext}
                    />
                  </Col>
                </Row>
                <PageDetailHeader>
                  Director of Clinical Services
                </PageDetailHeader>
                <Row>
                  <Col sm={3}>
                    <H4>Name</H4>
                    <Input
                      placeholder="First & Last Name"
                      type="director_name"
                      refs="director_name"
                      name="director_name"
                      value={this.state.director_name}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.director_name)}
                      error={this.state.formErrors.director_name}
                    />
                  </Col>
                  <Col sm={3}>
                    <H4>Email</H4>
                    <Input
                      type="director_email"
                      refs="director_email"
                      name="director_email"
                      value={this.state.director_email}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.director_email)}
                      error={this.state.formErrors.director_email}
                    />
                  </Col>
                  <Col sm={3}>
                    <H4>Phone</H4>
                    <Input
                      type="director_phone"
                      refs="director_phone"
                      name="director_phone"
                      value={this.state.director_phone}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.director_phone)}
                      error={this.state.formErrors.director_phone}
                    />
                  </Col>
                  <Col sm={1}>
                    <H4>Ext</H4>
                    <Input
                      type="director_ext"
                      refs="director_ext"
                      name="director_ext"
                      value={this.state.director_ext}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.director_ext)}
                      error={this.state.formErrors.director_ext}
                    />
                  </Col>
                </Row>
                <PageDetailHeader>Licenses & Certifications</PageDetailHeader>
                <Row>
                  <Col sm={3}>
                    <H4>CMS Certification #</H4>
                    <Input
                      type="cms_certification_number"
                      refs="cms_certification_number"
                      name="cms_certification_number"
                      value={this.state.cms_certification_number}
                      onChange={this.handleUserInput}
                      isError={Boolean(
                        this.state.formErrors.cms_certification_number,
                      )}
                      error={this.state.formErrors.cms_certification_number}
                    />
                  </Col>
                  <Col sm={2}>
                    <H4>Expiration Date</H4>
                    <Input
                      placeholder="mm/dd/yyyy"
                      type="cms_certification_expired_at"
                      refs="cms_certification_expired_at"
                      name="cms_certification_expired_at"
                      value={this.state.cms_certification_expired_at}
                      onChange={this.handleUserInput}
                      isError={Boolean(
                        this.state.formErrors.cms_certification_expired_at,
                      )}
                      error={this.state.formErrors.cms_certification_expired_at}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <H4>State Business License/Sunbiz #</H4>
                    <Input
                      type="business_license_number"
                      refs="business_license_number"
                      name="business_license_number"
                      value={this.state.business_license_number}
                      onChange={this.handleUserInput}
                      isError={Boolean(
                        this.state.formErrors.business_license_number,
                      )}
                      error={this.state.formErrors.business_license_number}
                    />
                  </Col>
                  <Col sm={2}>
                    <H4>Expiration Date</H4>
                    <Input
                      placeholder="mm/dd/yyyy"
                      type="business_license_expired_at"
                      refs="business_license_expired_at"
                      name="business_license_expired_at"
                      value={this.state.business_license_expired_at}
                      onChange={this.handleUserInput}
                      isError={Boolean(
                        this.state.formErrors.business_license_expired_at,
                      )}
                      error={this.state.formErrors.business_license_expired_at}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <H4>AHCA #</H4>
                    <Input
                      type="ahca_number"
                      refs="ahca_number"
                      name="ahca_number"
                      value={this.state.ahca_number}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.ahca_number)}
                      error={this.state.formErrors.ahca_number}
                    />
                  </Col>
                  <Col sm={2}>
                    <H4>Expiration Date</H4>
                    <Input
                      placeholder="mm/dd/yyyy"
                      type="ahca_expired_at"
                      refs="ahca_expired_at"
                      name="ahca_expired_at"
                      value={this.state.ahca_expired_at}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.ahca_expired_at)}
                      error={this.state.formErrors.ahca_expired_at}
                    />
                  </Col>
                </Row>
                <PageDetailHeader>Default Rates</PageDetailHeader>
                <Row>
                  <Col sm={1.6}>
                    <H4>Visit (PT)</H4>
                    <Input
                      placeholder="$"
                      type="default_visit_rate"
                      refs="default_visit_rate"
                      name="default_visit_rate"
                      value={this.state.default_visit_rate}
                      onChange={this.handleUserInput}
                      isError={Boolean(
                        this.state.formErrors.default_visit_rate,
                      )}
                      error={this.state.formErrors.default_visit_rate}
                    />
                  </Col>
                  <Col sm={1.6}>
                    <H4>DC</H4>
                    <Input
                      placeholder="$"
                      type="default_dc_rate"
                      refs="default_dc_rate"
                      name="default_dc_rate"
                      value={this.state.default_dc_rate}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.default_dc_rate)}
                      error={this.state.formErrors.default_dc_rate}
                    />
                  </Col>
                  <Col sm={1.6}>
                    <H4>Eval</H4>
                    <Input
                      placeholder="$"
                      type="default_eval_rate"
                      refs="default_eval_rate"
                      name="default_eval_rate"
                      value={this.state.default_eval_rate}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.default_eval_rate)}
                      error={this.state.formErrors.default_eval_rate}
                    />
                  </Col>
                  <Col sm={1.6}>
                    <H4>Oasis SOC</H4>
                    <Input
                      placeholder="$"
                      type="default_oasis_soc_rate"
                      refs="default_oasis_soc_rate"
                      name="default_oasis_soc_rate"
                      value={this.state.default_oasis_soc_rate}
                      onChange={this.handleUserInput}
                      isError={Boolean(
                        this.state.formErrors.default_oasis_soc_rate,
                      )}
                      error={this.state.formErrors.default_oasis_soc_rate}
                    />
                  </Col>
                  <Col sm={1.6}>
                    <H4>Oasis DOC</H4>
                    <Input
                      placeholder="$"
                      type="default_oasis_doc_rate"
                      refs="default_oasis_doc_rate"
                      name="default_oasis_doc_rate"
                      value={this.state.default_oasis_doc_rate}
                      onChange={this.handleUserInput}
                      isError={Boolean(
                        this.state.formErrors.default_oasis_doc_rate,
                      )}
                      error={this.state.formErrors.default_oasis_doc_rate}
                    />
                  </Col>
                  <Col sm={1.6}>
                    <H4>Visit (PTA)</H4>
                    <Input
                      placeholder="$"
                      type="default_visit_pta_rate"
                      refs="default_visit_pta_rate"
                      name="default_visit_pta_rate"
                      value={this.state.default_visit_pta_rate}
                      onChange={this.handleUserInput}
                      isError={Boolean(
                        this.state.formErrors.default_visit_pta_rate,
                      )}
                      error={this.state.formErrors.default_visit_pta_rate}
                    />
                  </Col>
                  <Col sm={1.6}>
                    <H4>Re-eval</H4>
                    <Input
                      placeholder="$"
                      type="default_reeval_rate"
                      refs="default_reeval_rate"
                      name="default_reeval_rate"
                      value={this.state.default_reeval_rate}
                      onChange={this.handleUserInput}
                      isError={Boolean(
                        this.state.formErrors.default_reeval_rate,
                      )}
                      error={this.state.formErrors.default_reeval_rate}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={1.6}>
                    <H4>Oasis DC</H4>
                    <Input
                      placeholder="$"
                      type="default_oasis_dc_rate"
                      refs="default_oasis_dc_rate"
                      name="default_oasis_dc_rate"
                      value={this.state.default_oasis_dc_rate}
                      onChange={this.handleUserInput}
                      isError={Boolean(
                        this.state.formErrors.default_oasis_dc_rate,
                      )}
                      error={this.state.formErrors.default_oasis_dc_rate}
                    />
                  </Col>
                  <Col sm={1.6}>
                    <H4>Oasis Re-cert</H4>
                    <Input
                      placeholder="$"
                      type="default_oasis_rechart_rate"
                      refs="default_oasis_rechart_rate"
                      name="default_oasis_rechart_rate"
                      value={this.state.default_oasis_rechart_rate}
                      onChange={this.handleUserInput}
                      isError={Boolean(
                        this.state.formErrors.default_oasis_rechart_rate,
                      )}
                      error={this.state.formErrors.default_oasis_rechart_rate}
                    />
                  </Col>
                </Row>
                <PageDetailHeader>Medicare Week</PageDetailHeader>
                <Row>
                  <Col>
                    <DarkText>
                      Click on the day that your Medicare week "Begins" and when
                      it "Ends" on days of the week below.
                    </DarkText>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ExtraSmallText>Starts</ExtraSmallText>
                    <WeekButtonGroup
                      isHorizontal
                      buttons={updateButtons}
                      value={this.state.medical_week_start}
                      onChange={this.handleStartChange}
                    />
                  </Col>
                  <Col>
                    <ExtraSmallText>Ends</ExtraSmallText>
                    <WeekButtonGroup
                      isHorizontal
                      buttons={updateButtons}
                      value={this.state.medial_week_end}
                      onChange={this.handleEndChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: '20px' }}>
                    <PromptText>{this.props.error}</PromptText>
                    <Button
                      style={{ width: 'calc(100% - 20px)' }}
                      theme="dark"
                      onClick={this.handleSubmit}
                      loading={this.props.loading}
                    >
                      Save Changes
                    </Button>
                  </Col>
                </Row>
              </ScrollWrapper>
            </AccountForm>
          </Card>
        </Row>
      </SideNavPage>
    );
  }
}

const mapStateToProps = state => ({
  details: state.agency.details,
  loading: state.loading['UPDATE_AGENCY_DETAILS'],
  error: state.error['UPDATE_AGENCY_DETAILS'],
});

const mapDispatchToProps = dispatch => ({
  getAgencyDetails: (resolve, reject) =>
    dispatch({ type: 'GET_AGENCY_DETAILS', resolve, reject }),
  updateAgencyDetails: (data, onSuccess, onError) =>
    dispatch({
      type: 'UPDATE_AGENCY_DETAILS',
      data,
      onSuccess,
      onError,
    }),
  logout: () => dispatch({ type: 'LOGOUT' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);
