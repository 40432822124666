import React from 'react';
import Navigation from 'navigation';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {Images} from 'listertech-commons-web';

const Navbar = props => {
  let { location, history, setVisitsTab } = props;
  let current = location.pathname;
  const navigationButtons = [
    {
      Title: 'Dashboard',
      Image: Images.dashboard,
      value: '/',
    },
    {
      Title: 'Visits',
      Image: Images.visits,
      value: '/visits',
    },
    {
      Title: 'Contractors',
      Image: Images.contractors,
      value: '/contractors',
    },
    {
      Title: 'Agencies',
      Image: Images.contractors,
      value: '/agencies',
    },
  ];
  const bottomnavigationButtons = [
    {
      Title: 'Account',
      Image: Images.account,
      value: '/account',
    },
    {
      Title: 'Logout',
      Image: Images.logout,
      value: '/logout',
    },
  ];
  const handleNavigationChange = value => {
    if (value === '/logout') {
      props.logout();
      history.push('/');
    } else {
      let page = value;
      history.push(page);
      setVisitsTab('open');
    }
  };
  return (
    <div style={{ height: '100%' }}>
      <Navigation
        isVertical
        buttons={navigationButtons}
        value={current}
        onChange={handleNavigationChange}
      />
      <Navigation
        isVertical
        className="fixed"
        buttons={bottomnavigationButtons}
        value={current}
        onChange={handleNavigationChange}
      />
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch({ type: 'LOGOUT' }),
  setVisitsTab: tab => dispatch({ type: 'SET_VISITS_TAB', tab }),
});

export default withRouter(
  connect(
    null,
    mapDispatchToProps,
  )(Navbar),
);
