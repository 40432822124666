import * as Types from './actionTypes';

const saveDashboardData = (data) => ({
  type: Types.SAVE_DASHBOARD_DATA,
  data,
})

const updateAgencyPaused = (id, data) => ({
  type: Types.UPDATE_AGENCY_PAUSED,
  id,
  data,
});

export default {
  saveDashboardData,
  updateAgencyPaused,
}
