/* @flow */
import * as ActionTypes from './actionTypes';
import type { Contractor, ContractorsState } from './index';
import type { ContractorsActions } from './actionTypes';

const initialState: ContractorsState = {
  all: [],
  search: '',
};

export default function messages(
  state: ContractorsState = initialState,
  action: ContractorsActions,
): ContractorsState {
  switch (action.type) {
    case ActionTypes.PUT_CONTRACTORS:
      let all = [
        ...state.all.filter(
          v => !Boolean(action.contractors.find(nv => nv._id === v._id)),
        ),
        ...action.contractors,
      ];
      return {
        ...state,
        all: all,
      };
    case 'SET_CONTRACTORS_SEARCH':
      return {
        ...state,
        search: action.search,
      }
    default:
      return state;
  }
}
