/* @flow */
import React from 'react';
import moment from 'moment';
import { ActionButton, DataTable, ListLargeText } from 'listertech-commons-web';
import type { Visit } from 'data/visits';

type Props = {
  data: Visit[],
  onSort: (index: number) => void,
  onVerify: (id: string) => void,
  onReject: (id: string) => void,
};

const PendingTable = (props: Props) => {
  return (
    <DataTable
      name="pending-visits"
      headers={[
        { title: 'Patient Name', size: { xs: 2 } },
        { title: 'Date', size: { xs: 1 } },
        { title: 'Type', size: { xs: 2 } },
        { title: 'Contractor', size: { xs: 2 } },
        { title: 'Agency', size: { xs: 2 } },
        { title: 'Action', size: { xs: 3 } },
      ]}
      data={props.data}
      renderers={[
        (v: Visit) => <ListLargeText>{v.patient.name}</ListLargeText>,
        (v: Visit) => (
          <ListLargeText>
            {v.submission && v.submission.submittedDate
              ? moment(v.submission.submittedDate).format('MM/DD')
              : ''}
          </ListLargeText>
        ),
        (v: Visit) => <ListLargeText>{v.type}</ListLargeText>,
        (v: Visit) => (
          <ListLargeText>{v.contractor ? v.contractor.name : ''}</ListLargeText>
        ),
        (v: Visit) => <ListLargeText>{v.agencyName}</ListLargeText>,
        (v: Visit) => (
          <React.Fragment>
            <ActionButton
              green
              onClick={e => {
                e.preventDefault();
                props.onVerify(v.id);
              }}
              key={`pending-visits-verify-${v.id}`}
            >
              Verify
            </ActionButton>
            <ActionButton
              red
              onClick={e => {
                e.preventDefault();
                props.onReject(v.id);
              }}
              key={`pending-visits-reject-${v.id}`}
            >
              Reject
            </ActionButton>
          </React.Fragment>
        ),
      ]}
      onSort={props.onSort}
    />
  );
};

export default PendingTable;
