/* @flow */
import React from 'react';
import moment from 'moment';
import { DataTable, ListLargeText, ActionButton, Images } from 'listertech-commons-web';

import type { Visit } from 'data/visits';

type Props = {
  data: any[],
  onSort: (index: number) => void,
};

const AgenciesTable = (props: Props) => {
  return (
    <DataTable
      name="agencies"
      headers={[
        { title: 'Agency', size: { xs: 3 } },
        { title: 'Primary Contact', size: { xs: 3 } },
        { title: 'Active Visits', size: { xs: 1 } },
        { title: 'Contractors', size: { xs: 1 } },
        { title: 'Action', size: { xs: 3 } },
      ]}
      data={props.data}
      renderers={[
        (a: any) => <ListLargeText>{a.name}</ListLargeText>,
        (a: any) => <ListLargeText>{a.director_name}</ListLargeText>,
        (a: any) => <ListLargeText>{a.visitsCount}</ListLargeText>,
        (a: any) => <ListLargeText>{a.contractorsCount}</ListLargeText>,
        (a: any) => (
          <>
            <ActionButton
              key={`agencies-details-${a._id}`}
              onClick={() => {
                props.onDetails(a._id);
              }}
            >
              Details
            </ActionButton>
            <ActionButton
              orange
              icon={Images.pauseOrange}
              key={`agencies-pause-${a._id}`}
              onClick={() => props.onTogglePaused(a._id, !a.paused)}
            >
              {a.paused ? 'Resume' : 'Pause'}
            </ActionButton>
          </>
        ),
      ]}
      onSort={props.onSort}
    />
  );
};

export default AgenciesTable;
