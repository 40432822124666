/* @flow */
import { call, callFormEncoded } from '../core';

export const callLogin = (email: string, password: string) =>
  callFormEncoded('users/login?superAdmin=1', 'POST', {
    body: { email, password },
  });

export const callResetLink = (email: string) =>
  callFormEncoded('users/reset', 'POST', { body: { email } });

export const callChangePassword = (token: string, password: string) =>
  callFormEncoded(`users/reset/${token}`, 'POST', { body: { password } });

export const callGetOne = (id: string) => call(`users/${id}`, 'GET', {});
