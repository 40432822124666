/* @flow */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row } from 'react-grid-system';
import {
  Button,
  Checkbox,
  Images,
  InputClearable,
  LoadingIndicator,
  Modal,
  ModalTitle,
  PromptText,
} from 'listertech-commons-web';
import { callGetAgencies, callEnrollMany } from '../../api/agencies';
import { callGetOne } from '../../api/users';

const ModalButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 400px;
  margin-top: 40px;

  > * {
    width: auto;
    padding: 0px 20px;
  }
`;

const Title = styled(ModalTitle)`
  display: inline-block;
`;

const TitleExtra = styled.p`
  display: inline-block;
  margin: 0;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #9da8ae;
`;

const Label = styled(TitleExtra)`
  font-size: 16px;
`;

const Icon = styled.img`
  width: 18px;
  height: 18px;
  margin-left: 12px;
`;

const IconLabel = styled(Label)`
  margin-left: 12px;
`;

const List = styled.div`
  margin-top: 12px;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const ListItem = styled.div`
  display: flex;
  margin-top: 12px;
`;

const AssignmentsModal = props => {
  const { contractor, onClose, onSearchUpdate } = props;

  const [agencies, setAgencies] = useState([]);
  const [allAgencies, setAllAgencies] = useState([]);
  const [enrolledAgencies, setEnrolledAgencies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [settled, setSettled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingEnroll, setLoadingEnroll] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (contractor) {
      fetchContractorAgencies();
    }
  }, [contractor]);

  useEffect(() => {
    fetchAgencies();
  }, [enrolledAgencies]);

  useEffect(() => {
    if (settled) {
      fetchAgencies();
    }
  }, [searchTerm, settled]);

  const updateAgencies = agencies => {
    setError('');
    const allAgenciesList = agencies
      ? agencies.map(a => {
          const ea = enrolledAgencies.find(ea => ea.name === a.name);
          const state = ea ? ea.state : '';
          const icon =
            state &&
            {
              pending: Images.pending,
              accepted: Images.accepted,
              rejected: Images.rejected,
            }[state];
          return {
            ...a,
            checked: a.checked || state === 'accepted',
            disabled: Boolean(state === 'accepted'),
            state,
            icon,
          };
        })
      : [];
    const key = 'name';
    const uniqueAgencies = [
      ...new Map(allAgenciesList.map(item => [item[key], item])).values(),
    ];
    setAgencies(uniqueAgencies);
    setAllAgencies(allAgenciesList);
  };

  const updateAgencyChecked = (agency, checked) => {
    setError('');
    setAgencies(
      agencies.map(a => (a.name === agency.name ? { ...a, checked } : a)),
    );
  };

  const updateSearchTerm = searchTerm => {
    setLoading(true);
    setSearchTerm(searchTerm);
  };

  const fetchContractorAgencies = async () => {
    try {
      const data = await callGetOne(contractor._id);
      setEnrolledAgencies(
        data.enrolled_agencies.map(ea => ({
          id: ea.agency_id,
          state: ea.state,
          name: ea.name,
        })),
      );
    } catch (e) {
      setEnrolledAgencies([]);
    }
  };

  // const fetchAgencies = async () => {
  //   setLoading(true);
  //   setSettled(false);
  //   try {
  //     const response: Object = await callGetAgencies(
  //       30,
  //       0,
  //       'name',
  //       false,
  //       searchTerm,
  //     );
  //     updateAgencies(response.data);
  //     setLoading(false);
  //   } catch (e) {
  //     alert(`Error: ${e.message}`);
  //     setLoading(false);
  //   }
  // };

  const fetchAgencies = async () => {
    setLoading(true);
    setSettled(false);
    try {
      let page_number = 0;
      let all_agencies = [];
      while (true) {
        const response: Object = await callGetAgencies(
          30,
          page_number,
          'name',
          false,
          searchTerm,
        );
        const total_count = response.headers.count;
        all_agencies = [...all_agencies, ...response.data];
        if (total_count <= (page_number + 1) * 30) {
          break;
        }
        page_number = page_number + 1;
      }
      updateAgencies(all_agencies);
      setLoading(false);
    } catch (e) {
      alert(`Error: ${e.message}`);
      setLoading(false);
    }
  };

  const handleEnroll = async () => {
    setError('');
    const agencyNames = agencies
      .filter(a => a.checked && !a.disabled)
      .map(a => a.name);
    if (!agencyNames.length) {
      return setError('Select a new agency to enroll');
    }
    const contractorId = contractor._id;
    const agencyIds = allAgencies
      .filter(obj => agencyNames.includes(obj.name))
      .map(obj => obj._id);
    try {
      setLoadingEnroll(true);
      await callEnrollMany(contractorId, agencyIds);
      await fetchContractorAgencies();
      setLoadingEnroll(false);
    } catch (e) {
      setError(e.message);
      setLoadingEnroll(false);
    }
  };

  return (
    <Modal visible>
      <Row justify="space-between" align="baseline">
        <Title>Choose Assignments</Title>
        <TitleExtra>{contractor && contractor.name}</TitleExtra>
      </Row>
      <Row align="center" style={{ marginTop: '30px' }}>
        <Label>Search</Label>
        <InputClearable
          containerStyle={{ flex: 1, width: 'auto', marginLeft: '15px' }}
          value={searchTerm}
          onChange={ev => updateSearchTerm(ev.target.value)}
          onClear={() => updateSearchTerm('')}
          onSettled={() => setSettled(true)}
        />
      </Row>
      {loading ? (
        <LoadingIndicator style={{ marginTop: '12px' }} />
      ) : agencies.length ? (
        <List>
          {agencies.map(it => (
            <ListItem key={`agency-${it.name}`}>
              <Checkbox
                label={it.name}
                checked={it.checked}
                disabled={it.disabled}
                onCheckedChange={checked => updateAgencyChecked(it, checked)}
              />
              {it.icon ? (
                <>
                  <Icon src={it.icon} />
                  <IconLabel>({it.state})</IconLabel>
                </>
              ) : null}
            </ListItem>
          ))}
        </List>
      ) : (
        <Label style={{ marginTop: '12px', color: '#354148' }}>
          No agencies found
        </Label>
      )}
      <ModalButtons>
        <Button noMargin theme="light" onClick={onClose}>
          Cancel
        </Button>
        <Button noMargin onClick={handleEnroll} loading={loadingEnroll}>
          Enroll
        </Button>
      </ModalButtons>
      {error ? (
        <PromptText style={{ marginTop: '12px', textAlign: 'center' }}>
          {error}
        </PromptText>
      ) : null}
    </Modal>
  );
};

export default AssignmentsModal;
