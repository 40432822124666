/* @flow */
import * as ActionTypes from './actionTypes';
import type { Visit } from './index';
import type {
  PutVisitsAction,
  AddVisitAction,
  UpdateVisitAction,
  VerifyVisitAction,
  RejectVisitAction,
} from './actionTypes';

const putVisits = (visits: Visit[]): PutVisitsAction => ({
  type: ActionTypes.PUT_VISITS,
  visits,
});

const putDashboardVisits = (visits: Visit[]): PutDashboardVisitsAction => ({
  type: ActionTypes.PUT_DASHBOARD_VISITS,
  visits,
});

const addVisit = (visit: Visit): AddVisitAction => ({
  type: ActionTypes.ADD_VISIT,
  visit,
});

const updateVisit = (visit: Visit): UpdateVisitAction => ({
  type: ActionTypes.UPDATE_VISIT,
  visit,
});

const verifyVisit = (id: string): VerifyVisitAction => ({
  type: ActionTypes.VERIFY_VISIT,
  id,
});

const rejectVisit = (id: string): RejectVisitAction => ({
  type: ActionTypes.REJECT_VISIT,
  id,
});

const cancelVisit = (id: string): RejectVisitAction => ({
  type: ActionTypes.CANCEL_VISIT,
  id,
});

const removeVisit = (id: string): RejectVisitAction => ({
  type: ActionTypes.REMOVE_VISIT,
  id,
});

export default {
  putVisits,
  putDashboardVisits,
  addVisit,
  updateVisit,
  verifyVisit,
  rejectVisit,
  cancelVisit,
  removeVisit,
};
