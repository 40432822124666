/* @flow */
import React, { useState, useEffect } from 'react';
import { Row } from 'react-grid-system';
import { connect } from 'react-redux';
import {
  ActionButton,
  PageHeader,
  LoadingIndicator,
  PromptText,
  DataTable,
  Images,
  ListLargeText,
  OptionButtonGroup,
  MenuOptionButton,
  MenuOptionsSearch,
  PageSelector,
  PopperActionButton,
} from 'listertech-commons-web';

import SideNavPage from 'components/SideNavPage';
import type { Contractor } from 'data/contractors';
import PaginationActions from '../../data/pagination/actions';
import { PAGE_SIZE } from '../../data/pagination/config';

import AssignmentsModal from './AssignmentsModal';

type Props = {
  loading: boolean,
  error: string,
  contractors: Contractor[],
  getContractors: (
    page: number,
    sort: string,
    desc: boolean,
    search: string,
    agencyId: string,
  ) => void,
};

type State = {
  selectedPillValue: Object,
};

const ContractorsPage = (props: Props) => {
  const ACTIVE_TABLE = {
    headers: ['Contractor', 'Active Visits', 'Action'],
    renderers: [
      (c: Contractor) => renderText(c.name),
      (c: Contractor) => renderText(c.visitsCount),
      (c: any) => renderActions(c),
    ],
  };

  const [agencyId, setAgencyId] = useState('');
  const [expandedMenuId, setExpandedMenuId] = useState('');
  const [selectedContractor, setSelectedContractor] = useState(null);

  const {
    loading,
    error,
    agencies,
    contractors,
    getContractors,
    setActivePage,
    history,
    paginator,
    search,
  } = props;

  useEffect(() => {
    fetchData(0, true);
  }, []);

  useEffect(() => {
    if (props.location.search !== agencyId) {
      fetchData(0, true);
      setAgencyId(props.location.search);
    }
  }, [props.location]);

  const handleDetails = (id: string) => {
    history.push(`/contractors/${id}`);
  };

  const handleToggleMenu = (id: string) => {
    setExpandedMenuId(id !== expandedMenuId ? id : '');
  };

  const handleAssign = contractor => {
    setExpandedMenuId('');
    setSelectedContractor(contractor);
  };

  const handlePageChange = page => {
    fetchData(page - 1);
  };

  const fetchData = (page: number, forceFetch: boolean) => {
    const agency = getAgency();

    if (!Boolean(paginator.pages[page]) || forceFetch) {
      getContractors(
        page,
        'created_at',
        true,
        props.search,
        agency ? agency._id : '',
      );
    } else {
      setActivePage('contractors', page);
    }
  };

  const handleSearchChange = (search: string) => {
    props.setSearch(search);
    fetchData(0, true);
  };

  const getAgency = () => {
    const match = new RegExp(/agency_id=(.*$)/).exec(props.location.search);

    if (!match) return null;

    const id = match[1];
    return props.agencies.find(a => a._id === id);
  };

  const renderText = value => <ListLargeText>{value}</ListLargeText>;

  const renderActions = c => (
    <>
      <ActionButton
        key={`actionDetails${c._id}`}
        onClick={e => handleDetails(c._id)}
      >
        Details
      </ActionButton>
      <PopperActionButton
        key={`actionMore${c._id}`}
        icon={Images.dotsVertical}
        onClick={() => handleToggleMenu(c._id)}
        style={{
          minWidth: '10px',
          width: '30px',
          backgroundColor: c._id === expandedMenuId ? '#A6A6A766' : undefined,
        }}
        iconStyle={{ marginRight: '0', height: '20px', width: '12px' }}
        expanded={c._id === expandedMenuId}
        menuItems={[{ text: 'Assign', onClick: () => handleAssign(c) }]}
      />
    </>
  );

  const agency = getAgency();
  const ids = paginator.pages[paginator.currentPage || 0] || [];
  const filteredContractors = contractors.filter(v => ids.includes(v._id));

  return (
    <SideNavPage>
      <Row justify="space-between">
        <PageHeader>{agency ? `${agency.name}'s` : ''} Contractors</PageHeader>
        <OptionButtonGroup>
          <MenuOptionsSearch
            initialValue={search}
            onChange={handleSearchChange}
          />
        </OptionButtonGroup>
      </Row>
      {loading ? (
        <LoadingIndicator />
      ) : error ? (
        <PromptText>{error}</PromptText>
      ) : (
        <DataTable
          headers={ACTIVE_TABLE.headers}
          renderers={ACTIVE_TABLE.renderers}
          data={filteredContractors}
          onSort={undefined}
        />
      )}
      <Row justify="flex-end" style={{ marginRight: 20 }}>
        {paginator.objectCount && paginator.pagesCount ? (
          <PageSelector
            total={paginator.objectCount}
            limit={PAGE_SIZE}
            pageCount={paginator.pagesCount}
            currentPage={paginator.currentPage + 1 || 1}
            onPageChange={handlePageChange}
          />
        ) : null}
      </Row>
      {Boolean(selectedContractor) ? (
        <AssignmentsModal
          contractor={selectedContractor}
          onClose={() => setSelectedContractor(null)}
        />
      ) : null}
    </SideNavPage>
  );
};

const mapStateToProps = state => ({
  loading: state.loading['GET_CONTRACTORS'],
  error: state.error['GET_CONTRACTORS'],
  contractors: state.contractors.all,
  search: state.contractors.search,
  paginator: state.pagination.contractors,
  agencies: state.agency.all,
});

const mapDispatchToProps = dispatch => ({
  getContractors: (page, sort, desc, search, agencyId) =>
    dispatch({ type: 'GET_CONTRACTORS', page, sort, desc, search, agencyId }),
  setSearch: search => dispatch({ type: 'SET_CONTRACTORS_SEARCH', search }),
  setActivePage: (key, page) =>
    dispatch(PaginationActions.setActivePage(key, page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractorsPage);
