/* @flow */
import type { Contractor } from './index';

export const PUT_CONTRACTORS = 'contractors/PUT_CONTRACTORS';

export type SetContractorsAction = {
  type: typeof PUT_CONTRACTORS,
  contractors: Contractor[],
};

export type ContractorsActions = SetContractorsAction
