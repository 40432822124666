import * as ActionTypes from './actionTypes';

const setUser = (id, email, agencyId) => ({
  type: ActionTypes.SET_USER,
  id,
  email,
  agencyId,
});

const clearUser = () => ({
  type: ActionTypes.CLEAR_USER,
});

export default {
  setUser,
  clearUser,
};
