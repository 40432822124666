/* @flow */
import * as ActionTypes from './actionTypes';
import type { Manager } from './index';
import type { SetManagersAction } from './actionTypes';

const setManagers = (managers: Manager[]): SetManagersAction => ({
  type: ActionTypes.SET_MANAGERS,
  managers,
});

const setDetails = details => ({
  type: ActionTypes.SET_DETAILS,
  details,
});

const putAgencies = agencies => ({
  type: ActionTypes.PUT_AGENCIES,
  agencies,
});

const updateAgencyPaused = (id, data) => ({
  type: ActionTypes.UPDATE_AGENCY_PAUSED,
  id,
  data,
});

const clear = () => ({
  type: ActionTypes.CLEAR,
});

export default {
  setManagers,
  setDetails,
  putAgencies,
  updateAgencyPaused,
  clear,
};
