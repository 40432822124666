import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import {
  DashboardOverviewElement,
  DashboardTableHeader as TableHeader,
  H5,
  LoadingIndicator,
  PromptText,
} from 'listertech-commons-web';

import SideNavPage from 'components/SideNavPage';
import AgenciesTable from './AgenciesTable';
import VisitsTable from './VisitsTable';

const COLORS = ['#B2DB88', '#5FB894', '#E4E986', '#86CB8E'];

class DashboardPage extends React.Component {
  componentDidMount(): void {
    this.props.getDashboardData();
  }

  onActiveDetails = id => {
    this.props.history.push(`/visits/${id}`);
  };

  viewAllVisits = (tab) => {
    this.props.history.push(`/visits?tab=${tab}`);
  };

  viewAllAgencies = () => {
    this.props.history.push('/agencies');
  }

  viewAgency = (id) => {
    this.props.history.push(`/agencies/${id}`);
  }

  onTogglePaused = (agencyId, newStatus) => {
    if (window.confirm(`Are you sure you want to ${newStatus ? 'pause' : 'resume'} this agency?`)) {
      this.props.updatePaused(agencyId, newStatus);
    }
  }

  render() {
    const { dashboard } = this.props;

    const count = dashboard.availableVisitsLength +
      dashboard.acceptedVisitsLength +
      dashboard.canceledVisitsLength +
      dashboard.pendingVisitsLength;

    const chartData = [
      { name: 'Available', value: Math.round(dashboard.availableVisitsLength / count * 100 || 0)},
      { name: 'Accepted', value: Math.round(dashboard.acceptedVisitsLength / count * 100 || 0)},
      { name: 'Canceled', value: Math.round(dashboard.canceledVisitsLength / count * 100 || 0)},
      { name: 'Pending', value: Math.round( dashboard.pendingVisitsLength / count * 100 || 0)},
    ];

    return (
      <SideNavPage>
        {this.props.loading ? (
          <LoadingIndicator />
          ) : this.props.error ? (
            <PromptText>{this.props.error}</PromptText>
          ) : (
            <>
              <Layout>
                <LayoutElement key="dash-overview" row="1" col="1">
                  <H5>Overview</H5>
                  <OverviewLayout>
                    <DashboardOverviewElement title="Visits" subtitle="Total" value={dashboard.visitsLength} background="#5FB89419" />
                    <DashboardOverviewElement title="Contractors" subtitle="Active" value={dashboard.contractorsLength} background="#86CB8E19" />
                    <DashboardOverviewElement title="Agencies" subtitle="Current" value={dashboard.agenciesLength} background="#B2DB8819" />
                  </OverviewLayout>
                </LayoutElement>
                <LayoutElement key="dash-chart" row="1" col="2">
                  <H5>Visits Breakdown</H5>
                  <ChartWrapper>
                    <ResponsiveContainer width={160} height={160}>
                      <PieChart>
                        <Pie
                          data={chartData}
                          innerRadius={50}
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {
                            chartData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                          }
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                    <Legend>
                      {chartData.map((entry, index) => <LegendElement key={`legend-${entry.name}`}><LegendCircle color={COLORS[index]}/>{entry.name}<span>({entry.value}%)</span></LegendElement>)}
                    </Legend>
                  </ChartWrapper>
                </LayoutElement>
                <LayoutElement key="dash-agencies" wide row="3" col="1 / 3" wideRow="2" wideCol="1 / 3">
                  <TableHeader title="Agencies" subtitle="Top Active" onButtonClick={this.viewAllAgencies}/>
                  <AgenciesTable onAgencyDetails={this.viewAgency} onTogglePaused={this.onTogglePaused} data={dashboard.agencies} />
                </LayoutElement>
                <LayoutElement key="dash-verified-visits" row="4" col="1">
                  <TableHeader title="Verified Visits" subtitle="Recent" onButtonClick={() => this.viewAllVisits('verified')}/>
                  <VisitsTable data={dashboard.visits.map(v => ({...v, link: `/visits/${v._id}`}))} />
                </LayoutElement>
              </Layout>
            </>
          )}
      </SideNavPage>
    );
  }
}

const OverviewLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 20px;
`;

const LayoutElement = styled.div`
  grid-column: ${props => props.col};
  grid-row: ${props => props.row};
  display: flex;
  flex-direction: column;

  ${props => props.wide && `
    @media (max-width: 1350px) {
      grid-column: ${props.wideCol};
      grid-row: ${props.wideRow};
    }
  `}

  h5 {
    width: 100%;
    margin: 0 0 15px;
    text-align: left;
  }
`;

const Layout = styled.div`
  padding-top: 20px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;

  @media (max-width: 993px) {
    grid-template-columns: auto;

    ${LayoutElement} {
      grid-column: auto;
      grid-row: auto;
    }
  }
`;

const ChartWrapper = styled.div`
  height: 100%;
  background-color: #298D7419;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
`;

const Legend = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 25px;
`;

const LegendElement = styled.div`
  display: flex;
  align-items: center;
  font-family: Montserrat;
  margin-bottom: 7px;
  font-size: 14px;

  span {
    font-weight: 200;
    margin-left: 5px;
  }
`;

const LegendCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: ${props => props.color};
  margin-right: 12px;
`;

const mapStateToProps = state => {
  return {
    loading: state.loading['GET_DASHBOARD'],
    error: state.error['GET_DASHBOARD'],
    dashboard: state.dashboard,
  };
};

const mapDispatchToProps = dispatch => ({
  getDashboardData: () => dispatch({ type: 'GET_DASHBOARD' }),
  updatePaused: (agencyId, status) => dispatch({ type: 'UPDATE_PAUSED', agencyId, status }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
