/* @flow */
import { call, callRaw } from '../core';
import type { RegisterAgencyDto } from './types';
import { PAGE_SIZE } from '../../data/pagination/config';

export const callRegister = (dto: RegisterAgencyDto) =>
  call('agencies', 'POST', {
    body: dto,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const callGetDetails = () => call('agencies/details', 'GET', {});

export const callUpdateDetails = data =>
  call('agencies/update-details', 'POST', {
    body: data,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const callGetContractors = (
  pageSize: number,
  page: number,
  sort: string,
  desc: boolean,
  search: string,
  agencyId: string,
) =>
  callRaw('users', 'GET', {
    params: {
      page_size: pageSize,
      page: page,
      sort_by: sort,
      desc: desc,
      search,
      agencyId,
    },
  });

export const callGetContractor = (id: string) => call(`users/${id}`, 'GET', {});

export const callGetAgencies = (
  pageSize: number,
  page: number,
  sort: string,
  desc: boolean,
  search: string,
) =>
  callRaw('agencies', 'GET', {
    params: {
      page_size: pageSize,
      page: page,
      sort_by: sort,
      desc,
      search,
    },
  });

export const callGetAgency = (id: string) => call(`agencies/${id}`, 'GET', {});

export const callUpdatePaused = (agency_id, paused) =>
  call('agencies/update-paused', 'PUT', { body: { agency_id, paused } });

export const callEnrollMany = (contractorId: string, agencyIds: string[]) =>
  call('agencies/enroll-many', 'POST', { body: { contractorId, agencyIds } });

export const callDenyContractor = (contractorId: string, agencyId: string) =>
  call('agencies/contractors/deny', 'POST', {
    body: { agencyId, contractorId },
  });
