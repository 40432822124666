/* @flow */
import React from 'react';
import { DataTable, ListLargeText, ActionButton, Images } from 'listertech-commons-web';

import type { Visit } from 'data/visits';

type Props = {
  data: Visit[],
  onDetails: (id: string) => void,
};

const AcceptedTable = (props: Props) => {
  return (
    <DataTable
      name="agencies"
      headers={[
        { title: 'Agency', size: { xs: 3 }},
        { title: 'POC', size: { xs: 3 }},
        { title: 'Active Visits', size: { xs: 1 }},
        { title: 'Contractors', size: { xs: 1 }},
        { title: 'Action', size: { xs: 4, xl: 3 }},
      ]}
      data={props.data}
      renderers={[
        (v: Visit) => <ListLargeText>{v.name}</ListLargeText>,
        (v: Visit) => <ListLargeText>{v.director_name}</ListLargeText>,
        (v: Visit) => (
          <ListLargeText>{v.visitsCount}</ListLargeText>
        ),
        (v: Visit) => (
          <ListLargeText>{v.contractorsLength}</ListLargeText>
        ),
        (v: Visit) => (
          <React.Fragment>
            <ActionButton
              key={`agencies-details-${v._id}`}
              onClick={() => props.onAgencyDetails(v._id)}
            >
              Details
            </ActionButton>
            <ActionButton
              orange
              icon={Images.pauseOrange}
              key={`agencies-pause-${v._id}`}
              onClick={() => props.onTogglePaused(v._id, !v.paused)}
            >
              {v.paused ? 'Resume' : 'Pause'}
            </ActionButton>
          </React.Fragment>
        ),
      ]}
      onSort={() => {}}
    />
  );
};

export default AcceptedTable;
