/* @flow */
import { call, put, takeLatest } from 'redux-saga/effects';
import DashboardActions from '../data/dashboard/actions';
import AgenciesActions from '../data/agencies/actions';
import VisitsActions from '../data/visits/actions';
import {callGetDashboard} from "../api/dashboard";
import {VisitsConverter} from "../api/visits/converters";

export function* getDashboardData(action: { id: string }): Generator<Object, void, void> {
  try {
    yield put({ type: 'GET_DASHBOARD_REQUEST' });
    const response: Object = yield call(callGetDashboard);
    yield put(DashboardActions.saveDashboardData(response));
    yield put(AgenciesActions.putAgencies(response.agencies));
    const entities = new VisitsConverter().toEntityArray(response.visits);
    yield put(VisitsActions.putVisits(entities));
    yield put({ type: 'GET_DASHBOARD_SUCCESS' });
  } catch (e) {
    yield put({ type: 'GET_DASHBOARD_FAILURE', error: e.message });
  }
}

const dashboardSaga = [
  takeLatest('GET_DASHBOARD', getDashboardData),
];

export default dashboardSaga;
