/* @flow */
import { call, put, takeLatest } from 'redux-saga/effects';
import { callGetDetails, callUpdateDetails, callGetAgencies, callGetAgency } from 'api/agencies';
import AgencyActions from 'data/agencies/actions';
import DashboardActions from 'data/dashboard/actions';
import PaginationActions from "../data/pagination/actions";
import {PAGE_SIZE} from "../data/pagination/config";
import {callUpdatePaused} from "../api/agencies";

export function* getDetails(action: {
  resolve: Function,
  reject: Function,
}): Generator<Object, void, void> {
  try {
    yield put({ type: 'GET_AGENCY_DETAILS_REQUEST' });
    const response: Object = yield call(callGetDetails);
    yield put(AgencyActions.setDetails(response));
    yield put({ type: 'GET_AGENCY_DETAILS_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'GET_AGENCY_DETAILS_FAILURE',
      error: error.message,
    });
  }
}

export function* updateDetails(action): Generator<Object, void, void> {
  try {
    yield put({ type: 'UPDATE_AGENCY_DETAILS_REQUEST' });
    const response: Object = yield call(callUpdateDetails, action.data);
    // yield authenticateUser(response);
    yield put({ type: 'UPDATE_AGENCY_DETAILS_SUCCESS' });
    yield put(AgencyActions.setDetails(action.data));
    yield call(action.onSuccess);
  } catch (error) {
    yield call(action.onError, error.message);
    yield put({
      type: 'UPDATE_AGENCY_DETAILS_FAILURE',
      error: error.message,
    });
  }
}

export function* getAgencies(action: {
  page: number,
  sort: string,
  desc: boolean,
  search?: string,
}): Generator<Object, void, void> {
  try {
    yield put({ type: 'GET_AGENCIES_REQUEST' });
    const response: Object = yield call(
      callGetAgencies,
      PAGE_SIZE,
      action.page,
      action.sort,
      action.desc,
      action.search
    );
    yield put(AgencyActions.putAgencies(response.data));
    yield put(
      PaginationActions.setPage(
        'agencies',
        action.page,
        response.headers.count,
        response.data.map(v => v._id),
      ),
    );
    yield put({ type: 'GET_AGENCIES_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'GET_AGENCIES_FAILURE',
      error: error.message,
    });
  }
}

export function* getAgency(action: {
 id: string
}): Generator<Object, void, void> {
  try {
    yield put({ type: 'GET_AGENCY_REQUEST' });
    const response: Object = yield call(callGetAgency, action.id);
    yield put(AgencyActions.putAgencies(response ? [response] : []));
    yield put({ type: 'GET_AGENCY_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'GET_AGENCY_FAILURE',
      error: error.message,
    });
  }
}

export function* updatePaused(action): Generator<Object, void, void> {
  try {
    yield put({ type: 'UPDATE_PAUSED_REQUEST' });
    const response = yield call(callUpdatePaused, action.agencyId, action.status);
    yield put({ type: 'UPDATE_PAUSED_SUCCESS' });
    yield put(AgencyActions.updateAgencyPaused(action.agencyId, response));
    yield put(DashboardActions.updateAgencyPaused(action.agencyId, response));
  } catch (error) {
    yield put({
      type: 'UPDATE_PAUSED_FAILURE',
      error: error.message,
    });
  }
}

const agenciesSaga = [
  takeLatest('GET_AGENCIES', getAgencies),
  takeLatest('GET_AGENCY', getAgency),
  takeLatest('GET_AGENCY_DETAILS', getDetails),
  takeLatest('UPDATE_AGENCY_DETAILS', updateDetails),
  takeLatest('UPDATE_PAUSED', updatePaused)
];

export default agenciesSaga;
