/* @flow */

import React from 'react';
import moment from 'moment';
import { Row, Col, Visible } from 'react-grid-system';
import type { RouterHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import styled from 'styled-components';
import {
  DarkText,
  Divider,
  ExpandableList,
  Field,
  IconText,
  Images,
  LoadingIndicator,
  PageHeader,
  OptionButtonGroup,
  MenuOptionButton,
  PageDetailHeader,
} from 'listertech-commons-web';

import SideNavPage from 'components/SideNavPage';
import type { Contractor } from 'data/contractors';

import Rating from './Rating';

const Text = styled.p`
  font-family: Montserrat;
`;

const ViewMore = styled.div`
  margin-top: 25px;
`;

const AgencyItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  align-items: center;
`;

const AgencyItemClose = styled.img`
  margin-left: 12px;
  cursor: pointer;
  padding: 5px;

  &:hover {
    background-color: #ffcccc;
  }
`;

type Props = {
  history: RouterHistory,
  match: any,
  contractor: ?Contractor,
};

class ContractorDetailsPage extends React.Component<Props> {
  componentDidMount() {
    if (!this.props.contractor) {
      this.props.getContractor(this.props.match.params.contractorId);
    }
  }

  showConfirmAlert = (title, message, onConfirm) => {
    confirmAlert({
      title,
      message,
      buttons: [
        {
          label: 'Yes',
          onClick: onConfirm,
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  handleView = (doctype, filename, contractorId) =>
    this.props.viewDocument(doctype, filename, contractorId);

  handleRemoveAgency = (agencyEnrollment, contractor) =>
    this.showConfirmAlert(
      'Confirm removal',
      `Are you sure you want to remove the contractor from the agency ${agencyEnrollment.name}? The status will be changed to rejected.`,
      () =>
        this.props.denyContractorForAgency(
          contractor._id,
          agencyEnrollment.agency_id,
          () => window.location.reload(),
        ),
    );

  getFriendlyName = url => {
    const split = url.split('/');
    const filename = split[split.length - 1];

    return filename.replace('%20', ' ');
  };

  viewAllDocuments = () => {
    const { _id: id } = this.props.contractor;
    this.props.history.push(`/documents/${id}`);
  };

  renderDoc = (doc, contractorId) =>
    doc.files.map(f => (
      <Col key={f.url} sm={12} md={6} lg={3} xl={3}>
        <Field
          label={
            doc.expiresAt
              ? `Expires ${moment(doc.expiresAt).format('MM/DD/YYYY')}`
              : 'No expiry date'
          }
          value={this.getFriendlyName(f.url)}
        />
        <MenuOptionButton
          onClick={() => this.handleView(doc.doctype, f.filename, contractorId)}
        >
          View
        </MenuOptionButton>
      </Col>
    ));

  renderNA = () => (
    <Col sm={12} md={6} lg={3} xl={3}>
      <Text>N/A</Text>
    </Col>
  );

  renderAgencies = () => {
    const { contractor } = this.props;
    const agencies = contractor
      ? contractor.enrolled_agencies &&
        contractor.enrolled_agencies.filter(a => a.state === 'accepted')
      : [];
    return agencies.length > 0 ? (
      <ExpandableList
        data={agencies}
        renderItem={it => (
          <AgencyItemContainer key={`expandable-list-item-${it.name}`}>
            <IconText
              icon={Images.accepted}
              label={it.name}
              style={{ display: 'inline-block' }}
            />
            <AgencyItemClose
              src={Images.closeDark}
              onClick={() => this.handleRemoveAgency(it, contractor)}
            />
          </AgencyItemContainer>
        )}
        expandLabel="All active enrollments"
        collapseLabel="Hide active enrollments"
        maxItemsNoExpansion={2}
      />
    ) : (
      <DarkText>No active agencies</DarkText>
    );
  };

  render() {
    const { contractor = {} } = this.props;

    const neededCertifications = [
      'PROFESSIONAL_INSURANCE',
      'AUTO_INSURANCE',
      'CPR_CARD',
    ];
    const neededLicences = ['PROFESSIONAL_LICENSE', 'DRIVER_LICENSE'];

    let certifications = [];
    let licenses = [];

    if (contractor.documents) {
      certifications = contractor.documents.filter(
        d => neededCertifications.indexOf(d.doctype) !== -1,
      );
      licenses = contractor.documents.filter(
        d => neededLicences.indexOf(d.doctype) !== -1,
      );
    }

    if (this.props.loading)
      return (
        <SideNavPage>
          <LoadingIndicator />
        </SideNavPage>
      );

    return (
      <SideNavPage>
        <Row justify="between">
          <PageHeader subtitle="Contractor">{contractor.name}</PageHeader>
          <OptionButtonGroup>
            <MenuOptionButton onClick={this.handleBack}>Back</MenuOptionButton>
          </OptionButtonGroup>
        </Row>
        <Row>
          <Col sm={12} lg={9}>
            <PageDetailHeader>Visits</PageDetailHeader>
          </Col>
          <Visible lg xl>
            <Col lg={3}>
              <PageDetailHeader>Agencies</PageDetailHeader>
            </Col>
          </Visible>
        </Row>
        <Divider />
        <Row>
          <Col xs={12} sm={6} md={4} lg={3} xl={3}>
            <Field label="Active" value={contractor.visitsCount} />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3} xl={3}>
            <Field label="Pending" value={contractor.pendingVisitsCount} />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3} xl={3}>
            <Field label="Canceled" value={contractor.canceledVisitsCount} />
          </Col>
          <Visible lg xl>
            <Col lg={3} xl={3}>
              {this.renderAgencies()}
            </Col>
          </Visible>
        </Row>
        <Visible xs sm md>
          <PageDetailHeader>Agencies</PageDetailHeader>
          <Divider />
          <Row>
            <Col xs={12}>{this.renderAgencies()}</Col>
          </Row>
        </Visible>
        <PageDetailHeader>General Info</PageDetailHeader>
        <Divider />
        <Row>
          <Col sm={12} md={6} lg={3} xl={3}>
            <Field label="Name" value={contractor.name} />
          </Col>
          <Col sm={12} md={6} lg={3} xl={3}>
            <Field label="email" value={contractor.email} />
          </Col>
        </Row>
        <PageDetailHeader>Certifications</PageDetailHeader>
        <Divider />
        <Row>
          {certifications.length
            ? certifications.map(doc => this.renderDoc(doc, contractor._id))
            : this.renderNA()}
        </Row>
        <PageDetailHeader>Licences</PageDetailHeader>
        <Divider />
        <Row>
          {licenses.length
            ? licenses.map(doc => this.renderDoc(doc, contractor._id))
            : this.renderNA()}
        </Row>
        <ViewMore>
          <MenuOptionButton onClick={this.viewAllDocuments}>
            View All Documents
          </MenuOptionButton>
        </ViewMore>
        <PageDetailHeader>Transportation</PageDetailHeader>
        <Divider />
        <Row>
          <Col sm={12} md={6} lg={3} xl={3}>
            <Field
              label="Type"
              value={contractor.transportation_type || 'N/A'}
            />
          </Col>
          <Col sm={12} md={6} lg={3} xl={3}>
            <Field
              label="Make"
              value={contractor.transportation_make || 'N/A'}
            />
          </Col>
          <Col sm={12} md={6} lg={3} xl={3}>
            <Field
              label="Model"
              value={contractor.transportation_model || 'N/A'}
            />
          </Col>
          <Col sm={12} md={6} lg={3} xl={3}>
            <Field
              label="Plate #"
              value={contractor.transportation_plate_number || 'N/A'}
            />
          </Col>
        </Row>
      </SideNavPage>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let contractor;
  contractor = state.contractors.all.find(
    v => v._id === ownProps.match.params.contractorId,
  );
  return {
    contractor,
    loading: state.loading['GET_CONTRACTOR'],
  };
};

const mapDispatchToProps = dispatch => ({
  viewDocument: (doctype: string, filename: string, contractorId: string) =>
    dispatch({ type: 'VIEW_DOCUMENT', doctype, filename, contractorId }),
  getContractor: (id: string) => dispatch({ type: 'GET_CONTRACTOR', id }),
  denyContractorForAgency: (
    contractorId: string,
    agencyId: string,
    onSuccess: Function,
  ) =>
    dispatch({
      type: 'DENY_CONTRACTOR_FOR_AGENCY',
      contractorId,
      agencyId,
      onSuccess,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContractorDetailsPage);
